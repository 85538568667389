export const FLAG = {
  SELLER_UNDER_OFFER_CONVERSIONS: 'swa_under_offer_conversions',
  SELLER_UNDER_OFFER_POC: 'seller_under_offer_poc',
  SKIP_EXPIRED_ENQUIRY: 'tc_skip_expired_enquiry',
  SWA_GLOBAL_PROGRESS: 'swa_global_progress',
  SWA_HOME_HERO_FONT: 'swa_new_brand_hero_font',
  SWA_PRIMARY_BUTTON: 'swa_primary_button',
  SWA_VEHICLE_HUB: 'swa_vehicle_hub',
} as const;

export const EVENT = {
  ENQUIRY_CREATED: 'enquiry_created',
  HERO_MILEAGE_CTA_CLICKED: 'valuations_hero_mileage_cta_clicked',
  HERO_VRM_CTA_CLICKED: 'valuations_hero_vrm_cta_clicked',
  OFFER_SELECT: 'offer_select',
  PS_ACCEPT_OFFER_CTA_CLICKED: 'ps_accept_offer_cta_clicked',
  PS_COMPLETE_SALE_CTA_CLICKED: 'ps_complete_sale_cta_clicked',
  SELLER_UNDER_OFFER_POC: 'post_sale_poc',
  VEHICLE_HUB_VIEW_SECTIONS: 'vehicle_hub_view_sections',
} as const;

export const EXPERIMENT_DECISIONS_HEADER = 'experiment-decisions';
